export class ApplicationSettingsHolder {

    private static applicationConfiguration: ApplicationConfiguration;

    public static initialize(authenticationConfiguration: ApplicationConfiguration): void {
        this.applicationConfiguration = authenticationConfiguration;
    }

    public static get configuration(): ApplicationConfiguration {
        return this.applicationConfiguration;
    }
}

export type ApplicationConfiguration = {
    authentication: AuthenticationConfiguration;
    apiConfiguration: ApiConfiguration,
    commonConfiguration: CommonConfiguration
}

export type AuthenticationConfiguration = {
    clientId: string;
    openIdServiceUrl: string;
    redirectUri: string;
    silentRedirectUri: string;
    postLogoutRedirectUri: string;
    responseType: string;
    scope: string;
}

export type ApiConfiguration = {
    pointsPresentationServiceUrl: string;
    managementObjectsPresentationServiceUrl: string;
    registrationServiceUrl: string;
}

export type CommonConfiguration = {
    contractStatementTemplate: string,
    isuTemplate: string
}