export enum AppRouteNames {
  Home = "Home",
  Welcome = "Welcome",
  FirstLogin = "FirstLogin",
  ManagementObject = "ManagementObject",
  PersonalAccount = "PersonalAccount",
  UserProfile = "UserProfile",
  Appeals = "Appeals",
  Notices = "Notices",
}

export function getPath(route: AppRouteNames): string {
  switch (route) {
    case AppRouteNames.Home:
      return "/isu";
    case AppRouteNames.Welcome:
      return "/welcome";
    case AppRouteNames.FirstLogin:
      return "/first-login";
    case AppRouteNames.ManagementObject:
      return "/management-object"
    case AppRouteNames.UserProfile:
      return "/user-profile"
    case AppRouteNames.PersonalAccount:
      return "/personal-area"
    case AppRouteNames.Appeals:
      return "/appeals"
    case AppRouteNames.Notices:
      return "/notices"
    default:
      throw "Route not found"
  }
}
