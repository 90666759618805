import { createRouter, RouteRecordRaw, createWebHistory, RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import Home from '../views/Home.vue'
import OidcCallback from '../views/OidcCallback.vue'
import OidcCallbackError from "../views/OidcCallbackError.vue"
import PageNotFound from "@/views/PageNotFound.vue"

import { authenticationModule } from "../store/modules/authentication";
import { managementObjectsModule as managementModule } from '@/store/modules/managementObjects';
import { AppRouteNames, getPath } from './route-names';
import { techConnectionModule as connectionModule, techConnectionModule } from '@/store/modules/techConnection'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Root',
    component: () => import('../views/Root.vue')
  },
  {
    path: getPath(AppRouteNames.Home),
    name: AppRouteNames.Home,
    component: Home,
    meta: {
      title: 'Интеллектуальная система учёта',
      useApplicationMenu: true,
      pageTite: 'ИСУ ТГК-2 Энергосбыт'
    },
    beforeEnter: homeAccess
  },
  {
    path: getPath(AppRouteNames.FirstLogin),
    name: AppRouteNames.FirstLogin,
    component: () => import('../views/FirstLogin.vue'),
    beforeEnter: firstLoginAccess
  },
  {
    path: getPath(AppRouteNames.ManagementObject),
    name: AppRouteNames.ManagementObject,
    component: () => import('../views/ManagementObject.vue'),
    meta: { title: 'Управление объектами' },
    beforeEnter: managementAccess
  },
  {
    path: getPath(AppRouteNames.Welcome),
    name: AppRouteNames.Welcome,
    component: () => import('../views/Welcome.vue')
  },
  {
    path: getPath(AppRouteNames.UserProfile),
    name: AppRouteNames.UserProfile,
    component: () => import('../views/UserProfile.vue')
  },
  {
    path: getPath(AppRouteNames.Notices),
    name: AppRouteNames.Notices,
    component: () => import('../views/Notifications.vue'),
    meta: {
      pageTite: 'Уведомления ТГК-2 Энергосбыт',
      title: 'Уведомления'
    }
  },
  {
    path: getPath(AppRouteNames.Appeals),
    name: 'Appeals',
    component: () => import('../views/Appeals.vue'),
    meta: {
      title: 'Обращения',
      pageTite: 'Обращения ТГК-2 Энергосбыт'
    }
  },
  {
    path: getPath(AppRouteNames.PersonalAccount),
    name: AppRouteNames.PersonalAccount,
    component: () => import('../views/PersonalAccount.vue'),
    meta: {
      title: 'Личный кабинет',
      useApplicationMenu: true,
      pageTite: 'ЛК ТГК-2 Энергосбыт'
    },
  },
  {
    path: '/oidc-callback',
    component: OidcCallback,
    props(route) {
      return route.query || {}
    }
  },
  {
    path: '/signin-oidc-error', // Needs to match redirectUri in you oidcSettings
    component: OidcCallbackError
  },
  {
    path: '/:catchAll(.*)',
    component: PageNotFound
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
});

function managementAccess(_to: RouteLocationNormalized, _: unknown, next: NavigationGuardNext) {
  if (!managementModule.existsManagementObjects && connectionModule.techConnectionRows.length === 0) {
    return next(getPath(AppRouteNames.FirstLogin))
  }
  return next()
}

function firstLoginAccess(_to: RouteLocationNormalized, _: unknown, next: NavigationGuardNext) {
  if (managementModule.existsManagementObjects || techConnectionModule.techConnectionRows.length > 0) {
    return next(getPath(AppRouteNames.ManagementObject))
  }
  return next()
}

function homeAccess(_to: RouteLocationNormalized, _: unknown, next: NavigationGuardNext) {
  if (!managementModule.existsApprovedManagementObjects) {
    return next(getPath(AppRouteNames.ManagementObject))
  }
  return next()
}

router.beforeEach(async (to, _, next) => {
  const isAuthenticatedUser = await authenticationModule.isAuthenticatedUser();

  if (!isAuthenticatedUser && to.name != AppRouteNames.Welcome && to.path !== "/oidc-callback") {
    return next(getPath(AppRouteNames.Welcome))
  }

  next()
})

export default router