import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "../index"
import ProxyFactory from "@/apiProxies/proxyFactory";
import { LegalPersonContractData, PrivatePersonContractData, TariffZone } from "@/apiProxies/dataContracts"
import { Indication, IndicationType, MeterDeviceData, PersonalAccountInfo, PointInfo, ReceiptOptions } from '@/apiProxies/dataContracts/personalAccount'
import dayjs from "dayjs"
import { personalAccountCache } from "./personalAccountCache";

@Module({ store, name: 'personalAccountModule', namespaced: true, dynamic: true, stateFactory: true })
export class PersonalAccountModule extends VuexModule {
    private _menuVisible = false

    private _indicationsLoading = true
    private _indications: Array<Indication> = []
    private _meterDeviceData: Array<MeterDeviceData> = []
    private _meterDeviceSelect: string | null = null
    private _indicationPointInfo: PointInfo | undefined
    private _indicationType: IndicationType = IndicationType.accepted
    private _addIndicationVisible = false
    private _filterBeginDate: Date = dayjs().add(-3, 'month').date(1).toDate()
    private _filterEndDate: Date = new Date()

    private _legalContracts: Array<LegalPersonContractData> = []
    private _privateContracts: Array<PrivatePersonContractData> = []
    private _personalAccountInfo: PersonalAccountInfo | undefined

    public get menuVisible(): boolean {
        return this._menuVisible
    }

    public get indicationPointInfo(): PointInfo | undefined {
        return this._indicationPointInfo
    }

    public get indications(): Indication[] {
        return this._indications
    }

    public get addIndicationVisible(): boolean {
        return this._addIndicationVisible
    }

    public get indicationType(): IndicationType {
        return this._indicationType
    }

    public get indicationsLoading(): boolean {
        return this._indicationsLoading
    }

    public get filterBeginDate(): Date {
        return this._filterBeginDate
    }

    public get filterEndDate(): Date {
        return this._filterEndDate
    }

    public get meterDeviceData(): MeterDeviceData[] {
        return this._meterDeviceData
    }

    public get meterDeviceSelect(): string | null {
        return this._meterDeviceSelect
    }

    public get privatePersonContract(): PrivatePersonContractData[] {
        return this._privateContracts
    }

    public get legalPersonContract(): LegalPersonContractData[] {
        return this._legalContracts
    }

    public get personalAccountInfo(): PersonalAccountInfo | undefined {
        return this._personalAccountInfo;
    }

    @Mutation
    setIndicationPointInfo(value: PointInfo | undefined): void {
        this._indicationPointInfo = value
    }

    @Mutation
    setAddIndicationVisible(value: boolean): void {
        this._addIndicationVisible = value
    }

    @Mutation
    setMenuVisible(value: boolean): void {
        this._menuVisible = value
    }

    @Mutation
    setMeterDeviceData(value: MeterDeviceData[]): void {
        this._meterDeviceData = value
    }

    @Mutation
    setMeterDeviceDataSelect(value: string | null): void {
        this._meterDeviceSelect = value
    }

    @Mutation
    setIndications(value: Indication[]): void {
        this._indications = value
    }

    @Mutation
    setIndicationType(value: IndicationType): void {
        this._indicationType = value
    }

    @Mutation
    setIndicationsLoading(value: boolean): void {
        this._indicationsLoading = value
    }

    @Mutation
    setIndicationsDates(value: [Date, Date]): void {
        this._filterBeginDate = value[0]
        this._filterEndDate = value[1]
    }

    @Mutation
    setPersonalAccountInfo(information: PersonalAccountInfo | undefined): void {
        this._personalAccountInfo = information
    }

    @Mutation
    setLegalContracts(contracts: Array<LegalPersonContractData>): void {
        this._legalContracts = contracts
    }

    @Mutation
    setPrivateContracts(contracts: Array<PrivatePersonContractData>): void {
        this._privateContracts = contracts
    }

    @Action
    public async loadMeterDeviceData(contractId: string): Promise<void> {
        const proxy = await ProxyFactory.createPersonalAccountPresenterProxy()

        const meterDeviceData = await proxy.getMeterDeviceData(contractId)

        this.setMeterDeviceData(meterDeviceData)
    }

    @Action
    public async loadIndications(body: { contractId: string, meterId: string | null, beginDate: Date, endDate: Date, indicationType: IndicationType }): Promise<void> {
        const proxy = await ProxyFactory.createPersonalAccountPresenterProxy()

        const indiactions = await proxy.getIndications(body.contractId, body.meterId, body.beginDate, body.endDate, body.indicationType)

        this.setIndications(indiactions)
    }

    @Action({ rawError: true })
    public async loadContracts(): Promise<void> {
        const proxy = await ProxyFactory.createPersonalAccountPresenterProxy()

        const privateContracts = proxy.getPrivateContracts()
        const legalContracts = proxy.getLegalContracts()

        await Promise.all([privateContracts, legalContracts])

        this.setPrivateContracts(await privateContracts)
        this.setLegalContracts(await legalContracts)
    }

    @Action({ rawError: true })
    public async loadReceiptOptions(contractId: string): Promise<ReceiptOptions> {
        const proxy = await ProxyFactory.createPersonalAccountPresenterProxy()

        return await proxy.getReceiptOptions(contractId)
    }

    @Action({ rawError: true })
    public async updateReceiptOptions(options: ReceiptOptions): Promise<void> {
        const proxy = await ProxyFactory.createPersonalAccountManagerProxy()

        await proxy.updateReceiptOptions(options)
    }

    @Action({ rawError: true })
    public async loadPersonalAccountInfo(contractId: string): Promise<void> {
        this.setPersonalAccountInfo(undefined)

        const proxy = await ProxyFactory.createPersonalAccountPresenterProxy()

        const result = await proxy.getInformation(contractId)

        if (personalAccountCache.selectedContract && contractId === personalAccountCache.selectedContract.id.toString()) {
            this.setPersonalAccountInfo(result)
        }
    }

    @Action({ rawError: true })
    public async addPersonalAccountIndication(body: { contractId: string, meterId: string, pointId: string, indications: { tariffZone: TariffZone, value: number }[] }): Promise<void> {
        const proxy = await ProxyFactory.createPersonalAccountManagerProxy()

        await proxy.addIndication(body.contractId, body.meterId, body.pointId, body.indications)
    }
}

export const personalAccountModule = getModule(PersonalAccountModule)