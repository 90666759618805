import { TariffZone } from "../dataContracts"

export type ReceiptOptions = {
    contractId: string,
    email?: string,
    sendByEmail: boolean,
    sendOnPaper: boolean
}

export type PersonalAccountInfo = {
    contractInfo: ContractInfo,
    pointsInfo: PointInfo[]
}

export type ContractInfo = {
    registeredCount: number,
    areaOfPremises: number,
    benefitsCount: number,
    companyName: string
}

export type PointInfo = {
    id: string,
    name: string,
    buildingName: string,
    intervalAccounting: boolean,
    meteringsPeriodEnd: number,
    meteringsPeriodBegin: number,
    meterDeviceInfo: MeterDeviceInfo,
    calculationMethod: PointCalculationMethod,
    lastMeterings: MeteringData[],
}

export enum PointCalculationMethod {
    indications,
    standard,
    substituteInformation,
    fixedFlow,
    byPower
}


export type MeterDeviceInfo = {
    id: string,
    number: string,
    scalesCount: number,
    digitsCount: number,
    type: string
}

export type MeteringData = {
    tariffZone: TariffZone,
    value: number,
    date: string,
}

export type PaymentDocument = {
    id: string,
    contractId: string,
    documentDate: string,
    paymentDocumentFiles: PaymentDocumentFile[]
}

export type PaymentDocumentFile = {
    fileId: string,
    fileType: PaymentDocumentFileType
}

export enum PaymentDocumentFileType {
    ReceiptSubscriber,
    UTD,
    FinalInvoice,
    IntermediatePaymentAccount
}

export type Indication = {
    date: string,
    value: number,
    tariffZone: TariffZone,
    source: MeteringSource,
    state?: IndicationState,
    hourly: boolean
}

export enum IndicationState {
    new,
    processed
}

export enum IndicationType {
    accepted,
    transferred
}

export type MeterDeviceData = {
    id: string,
    type: string,
    serialNumber: string
}

export enum MeteringSource {
    consumer,
    publicContractor,
    resourceOrganization,
    networkOrganization,
    replacementInformation
}

export type MutualSettlementData = {
    contractID: string,
    period: string,
    electricityBilled: number,
    contractPenaltiesBilled: number,
    legalPenaltiesBilled: number,
    taxBilled: number,
    paid: number,
    electricityDebt: number,
    contractPenaltiesDebt: number,
    legalPenaltiesDebt: number,
    taxDebt: number,
    compensation: number | null
}

export type BillingData = {
    contractID: string,
    period: string,
    accruals: AccrualData[]
}

export type AccrualData = {
    tariffZone: TariffZone,
    accrualType: AccrualType,
    quantity: number,
    price: number | null,
    amount: number
}

export enum AccrualType {
    ownConsumption,
    losses,
    houseConsumption,
    recalculation,
    benefits,
    benefitsRecalculation
}

export type PaymentDetail = {
    date: string,
    amount: number,
    source: string,
    state: PaymentState
}

export const getPaymentStatus = (status: PaymentState): string => {
    switch (status) {
        case PaymentState.new: return 'Обрабатывается'
        case PaymentState.paid: return 'Обработан'
        case PaymentState.notified: return 'Обработан'
        case PaymentState.checked: return 'Обработан'
        case PaymentState.fail: return 'Ошибка'
        case PaymentState.canceled: return 'Отменён'
    }
}

export enum PaymentType {
    all,
    lk
}

export const getAccrualTypeName = (zone: AccrualType): string => {
    switch (zone) {
        case AccrualType.ownConsumption: return 'Cобственное потребление'
        case AccrualType.losses: return 'Потери'
        case AccrualType.houseConsumption: return 'Общедомовое потребление'
        case AccrualType.recalculation: return 'Перерасчеты'
        case AccrualType.benefits: return 'Льготы'
        case AccrualType.benefitsRecalculation: return 'Перерасчеты льгот'
        default: throw new Error()
    }
}

export type PaymentRequestData = {
    contractId: string,
    amount: number,
    returnUrl: string,
    paymentMethod: PaymentMethod
}

export enum PaymentMethod {
    sber,
    sbp,
    vbrr
}

export enum PaymentOrderType {
    sbp,
    sber,
    vbrr
}

export enum PaymentState {
    new,
    paid,
    notified,
    checked,
    fail,
    canceled
}

export type PaymentOrderData = {
    order: number,
    paymentType: PaymentOrderType,
    paymentState: PaymentState,
    createdUtc: string,
    amount: number
}

export type SberResponseData = {
    orderId: string,
    formUrl: string,
    orderNumber: number
}

export type SbpResponseData = {
    QR_ID: string,
    QR_DATA: string,
    QR_IMG_REF: string,
    QR_PSB_ID: string,
    QR_IMG_DATA: string,
    orderNumber: number
}